import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { Heading32, Paragraph16 } from "../../styles/PageStyles";
import Layout from '../../components/shared/Layout'

const NewsTemplate = ({ data }) => {
  const { html } = data.markdownRemark;
  const { title, description } = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <StyledContainer fluid>
        <div className="heading">
          <Heading32 style={{ marginBottom: 10 }}>{title}</Heading32>
          <Paragraph16>{description}</Paragraph16>
        </div>
        <div className="body" dangerouslySetInnerHTML={{ __html: html }} />
      </StyledContainer>
    </Layout>
  );
};

export default NewsTemplate;

const StyledContainer = styled(Container)`
  padding: 190px 10% 100px 10%;
  min-height: 80vh;

  .heading {
    margin-bottom: 50px;
  }

  .body {
    * {
      margin-bottom: 1rem;
      text-align: justify;
      letter-spacing: 0.06em;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .body {
      * {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 769px) {
    padding: 120px 5% 50px 5%;
  }
`;

export const query = graphql`
  query NewsTemplate($id: String) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
